import appConfig from '@/app.config';
export default {
  page: {
    title: 'change language',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data() {
    return {
      language: 'english',
      color: '#f9aa4d'
    };
  },
  methods: {}
};
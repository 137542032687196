var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "mnotify-card",
    attrs: {
      "id": "account-settings"
    }
  }, [_c('div', {
    staticClass: "mnotify-card-header",
    attrs: {
      "id": "side-indicators-content"
    }
  }, [_c('div', {
    staticClass: "profile-settings-section-header"
  }, [_c('profilesettingsBtn'), _c('div', {
    staticClass: "profile-settings-sub-header"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text py-0 my-0"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.account_settings.change_language.title')))])])], 1)]), _c('div', {
    staticClass: "mnotify-card-body"
  }, [_c('div', {
    staticClass: "row profile-settings-form",
    staticStyle: {
      "margin-top": "2em"
    }
  }, [_c('div', {
    staticClass: "profile_form"
  }, [_c('p', {
    staticClass: "profile-see-more pb-4"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.account_settings.change_language.select')) + "please select your preferred language")]), _c('div', {
    staticClass: "profile-inner"
  }, [_c('div', {
    staticClass: "changelanguage"
  }, [_c('button', {
    class: [_vm.language === 'english' ? 'active' : ''],
    on: {
      "click": function ($event) {
        _vm.language = 'english';
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "width": "21",
      "src": require("@/assets/english.png"),
      "alt": "english"
    }
  }), _c('p', {
    staticClass: "__text ml-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.account_settings.change_language.english')) + " "), _vm.language === 'english' ? _c('span', {
    staticClass: "tick"
  }, [_c('img', {
    attrs: {
      "height": "40",
      "src": require("../../../assets/tick.svg"),
      "alt": ""
    }
  })]) : _vm._e()])]), _c('button', {
    class: [_vm.language === 'french' ? 'active' : ''],
    on: {
      "click": function ($event) {
        _vm.language = 'french';
      }
    }
  }, [_c('img', {
    staticClass: "mr-3",
    attrs: {
      "height": "16",
      "width": "21",
      "src": require("@/assets/french.png"),
      "alt": "french"
    }
  }), _c('p', {
    staticClass: "__text"
  }, [_vm._v(_vm._s(_vm.$t('dashboard.account_settings.change_language.french')) + " "), _vm.language === 'french' ? _c('span', {
    staticClass: "tick"
  }, [_c('img', {
    attrs: {
      "height": "40",
      "src": require("../../../assets/tick.svg"),
      "alt": ""
    }
  })]) : _vm._e()])])])])])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };